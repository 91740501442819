<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <ion-label class="fw-600 fs-3 pl-2">{{ $t('payment_summary') }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <ion-content :scroll-events="true">
      <div class="w-100 bg-gray-modal h-100 pt-5 pb-5" v-if="!isCreateTopUpPayment">
        <div class="pb-5 d-flex align-center justify-center flex-column">
          <ion-icon class="f-icon" :class="paymentStatus.color" :icon="paymentStatus.icon"></ion-icon>
          <ion-label class="fs-4 fw-600 mt-2 mb-2" :class="paymentStatus.color">
            {{ paymentStatus.headerLabel }}</ion-label
          >
        </div>
        <ion-grid class="w-90 rounded-sm shadow-sm px-3 pt-3 bg-white">
          <ion-row v-for="(item, i) in renderData" class="mb-2" :key="i">
            <ion-col v-if="item.shown">
              <ion-label class="fw-500 text-gray-700">{{ item.label }}</ion-label>
              <ion-row class="mt-2" v-for="(invoice, i) in item.invoices" :key="i">
                <ion-col>
                  <ion-label class="text-gray-700">{{ invoice.label }}</ion-label>
                </ion-col>
                <ion-col size="auto" push="0.6">
                  <ion-label class="fw-600 text-gray-700">{{ invoice.value }}</ion-label>
                </ion-col>
              </ion-row>
            </ion-col>
            <ion-col size="auto" v-if="item.shown">
              <ion-text class="fw-600 text-gray-700"> {{ item.value }}</ion-text>
            </ion-col>
          </ion-row>
          <ion-row class="mb-2">
            <div class="box mb-1">
              <ion-row class="ion-align-items-center ion-justify-content-between">
                <ion-col>
                  <ion-row>
                    <ion-label class="ml-2 mt-1 text-gray-700 fw-700">{{ remarksLabel }} </ion-label>
                  </ion-row>
                  <ion-row>
                    <ion-text class="ml-2 mt-2 mb-2 text-gray-700"
                      >{{ notes || referenceNumber || '-' }}
                    </ion-text>
                  </ion-row>
                </ion-col>
              </ion-row>
            </div>
          </ion-row>
          <ion-row class="mb-2">
            <ion-col>
              <ion-label class="text-primary-green-700 fw-500">{{ $t('applied_credits') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-text class="text-primary-green-700 fw-500">
                {{ !totalBalanceUsed ? '-' : priceFormat(totalBalanceUsed) }}</ion-text
              >
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="fw-600 text-gray-700">{{ $t('total') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-text class="fw-500" :class="paymentStatus.color"> {{ paymentStatus.label }}</ion-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col> </ion-col>
            <ion-col size="auto">
              <ion-text class="fw-700 text-gray-700"> {{ priceFormat(totalPaidAmount) }}</ion-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-button class="mb-2 mt-2" @click="$emit('backToInvoice')" expand="block" color="primary">
              <ion-label class="text-capitalize"> {{ $t('back_to_invoice') }} </ion-label>
            </ion-button>
            <ion-button class="mb-2" @click="$emit('backToHome')" fill="outline" expand="block">
              <ion-label class="text-capitalize"> {{ $t('back_to_home') }} </ion-label>
            </ion-button>
            <ion-button
              v-if="paymentStatus.value === PENDING_APPROVAL"
              class="mb-4"
              @click="onCancelPayment"
              fill="outline"
              expand="block"
              color="danger"
            >
              <ion-label class="text-capitalize"> {{ $t('cancel_payment') }} </ion-label>
            </ion-button>
          </ion-row>
        </ion-grid>
      </div>
      <div class="w-100 bg-gray-modal h-100 pt-5 pb-5" v-else>
        <div class="pb-5 d-flex align-center justify-center flex-column">
          <ion-icon class="f-icon" :class="paymentStatus.color" :icon="paymentStatus.icon"></ion-icon>
          <ion-label class="fs-4 fw-600 mt-2 mb-2" :class="paymentStatus.color">
            {{ paymentStatus.headerLabel }}</ion-label
          >
        </div>
        <ion-grid class="w-90 rounded-sm shadow-sm px-3 pt-3 bg-white">
          <ion-row class="mb-1 bor-bot">
            <ion-col>
              <ion-label class="fw-500 text-gray-700">{{ $t('top_up_credits') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-label class="fw-500 text-gray-700">{{
                priceFormat(+topUpSummaryData.totalPaidAmount)
              }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row class="mb-1">
            <ion-col>
              <ion-label class="fw-500 text-gray-700">{{ $t('payment_method') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-label class="fw-500 text-gray-700">{{
                INVOICE_PAYMENT_NAME[topUpSummaryData.paymentTypeId]
              }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row class="mb-1">
            <ion-col>
              <ion-label class="fw-500 text-gray-700">{{ $t('transaction_date') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-label class="fw-500 text-gray-700">{{
                formatDateMonthYear(paymentSummaryTopUp.details?.payment_date)
              }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row class="mb-1">
            <ion-col>
              <ion-label class="fw-500 text-gray-700">{{ $t('payment_value_date') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-label class="fw-500 text-gray-700">{{
                formatDateMonthYear(paymentSummaryTopUp.details?.payment_value_date)
              }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row class="mb-1">
            <ion-col>
              <ion-label class="fw-500 text-gray-700">{{ $t('payment_time') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-label class="fw-500 text-gray-700">{{
                formatDayDateTime(paymentSummaryTopUp.details?.created_at)
              }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row class="mb-1">
            <div class="box mb-2">
              <ion-row class="ion-align-items-center ion-justify-content-between">
                <ion-col>
                  <ion-row>
                    <ion-label class="ml-2 mt-1 text-gray-700">{{ remarksLabel }} </ion-label>
                  </ion-row>
                  <ion-row>
                    <ion-text class="ml-2 mt-2 mb-2 text-gray-700"
                      >{{ topUpSummaryData.notes || topUpSummaryData.referenceNumber || '-' }}
                    </ion-text>
                  </ion-row>
                </ion-col>
              </ion-row>
            </div>
          </ion-row>
          <ion-row class="bor-top">
            <ion-col>
              <ion-label class="fw-600 text-gray-700">{{ $t('total') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-text class="fw-500 text-primary-orange-500"> {{ $t('pending') }}</ion-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col> </ion-col>
            <ion-col size="auto">
              <ion-text class="fw-700 text-gray-700">
                {{ priceFormat(+topUpSummaryData.totalPaidAmount) }}</ion-text
              >
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-button
              class="mb-2 mt-2"
              @click="
                topUpEntryPoint === TOP_UP_CREDIT_ENTRY.ACCOUNT_PAGE
                  ? $emit('backToAccount')
                  : $emit('backToInvoice')
              "
              expand="block"
              color="primary"
            >
              <ion-label class="text-capitalize">
                {{
                  topUpEntryPoint === TOP_UP_CREDIT_ENTRY.ACCOUNT_PAGE
                    ? $t('back_to_account')
                    : $t('back_to_invoice')
                }}
              </ion-label>
            </ion-button>
            <ion-button class="mb-2" @click="$emit('backToHome')" fill="outline" expand="block">
              <ion-label class="text-capitalize">
                {{ $t('back_to_home') }}
              </ion-label>
            </ion-button>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
    <ion-loading :is-open="loadingCancelling" cssClass="custom-loading" spinner="crescent"> </ion-loading>
  </ion-page>
</template>
<script>
import { apolloClient } from '@/main';
import {
  INVOICE_PAYMENT_NAME,
  INVOICE_PAYMENT_TYPE,
  PAYMENT_HISTORY_LIST_STATUS,
  TOP_UP_CREDIT_ENTRY
} from '@/modules/shared/constants/';
import { cancelManualPayment } from '@/modules/shared/services/graphql';
import { toastError, useAlert } from '@/modules/shared/utils/';
import { useDateFormatter } from '@/usecases/global';
import { priceFormatter } from '@/utils';
import { useBackButton } from '@ionic/vue';
import { alertCircleOutline, checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';
import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ModalPaymentSummary',
  emits: ['backToInvoice', 'backToHome', 'cancelPayment', 'backToAccount'],
  props: {
    currencySymbol: {
      type: String,
      default: ''
    },
    invoice: {
      type: Array,
      default: () => []
    },
    isBankTransfer: {
      type: Boolean,
      default: false
    },
    paymentType: {
      type: Number,
      default: 0
    },
    paymentSummary: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const storage = inject('$storage');
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const { createAlertTwoAction, createAlert } = useAlert();
    const { isValidDate, formatDateMonthYear, formatDateTime, formatDayDateTime } = useDateFormatter();
    const { BANK_TRANSFER, CHEQUE, CASH_PAYMENT_COLLECTION, CASH_DEPOSIT } = INVOICE_PAYMENT_TYPE;
    const { PAID, REJECTED, CANCELLED, PENDING_APPROVAL } = PAYMENT_HISTORY_LIST_STATUS;
    const payloadCancel = ref({ tenantId: null, customerId: null });

    const paymentMethodVariants = {
      [BANK_TRANSFER]: t('bank_transfer'),
      [CHEQUE]: t('cheque'),
      [CASH_PAYMENT_COLLECTION]: t('cash_payment_collection'),
      [CASH_DEPOSIT]: t('cash_deposit')
    };
    const paymentStatusVariants = [
      {
        headerLabel: t('payment_pending'),
        icon: alertCircleOutline,
        color: 'text-primary-orange-500',
        value: PENDING_APPROVAL,
        label: t('pending')
      },
      {
        headerLabel: t('payment_success'),
        icon: checkmarkCircleOutline,
        color: 'text-primary-green-600',
        value: PAID,
        label: t('paid')
      },
      {
        headerLabel: t('payment_failed'),
        icon: closeCircleOutline,
        color: 'text-error-600',
        value: CANCELLED,
        label: t('order.cancelled')
      },
      {
        headerLabel: t('payment_failed'),
        icon: closeCircleOutline,
        color: 'text-error-600',
        value: REJECTED,
        label: t('rejected')
      }
    ];
    const remarksLabel = [BANK_TRANSFER, CASH_DEPOSIT].includes(props.paymentType)
      ? t('note_only')
      : t('transaction_reference_number');
    const notes = ref(props.paymentSummary?.notes ?? '-');
    const referenceNumber = ref(props.paymentSummary?.details?.reference_number ?? '-');
    const totalBalanceUsed = ref(props.paymentSummary.totalCustomerBalanceUsed) || 0;
    const totalPaidAmount = ref(props.paymentSummary.totalPaidAmount);
    const labelPaymentMethod = paymentMethodVariants[props.paymentType];
    const invoices = props.invoice.map((invoice) => {
      return {
        label: invoice.invoiceNumber,
        value: priceFormatter(props.currencySymbol, invoice.outstandingAmount)
      };
    });

    const dataBank = [
      {
        label: `${t('invoice_list')}:`,
        value: '',
        invoices,
        shown: true
      },
      {
        label: `${t('payment_method')}:`,
        value: labelPaymentMethod,
        shown: true
      },
      {
        label: `${t('transaction_date')}:`,
        value: isValidDate(props.paymentSummary?.transactionDate)
          ? formatDateMonthYear(props.paymentSummary?.transactionDate)
          : `-`,
        shown: true
      },
      {
        label: `${t('payment_value_date')}:`,
        value: isValidDate(props.paymentSummary?.valueDate)
          ? formatDateMonthYear(props.paymentSummary?.valueDate)
          : `-`,
        shown: true
      },
      {
        label: `${t('payment_time')}:`,
        value: formatDateTime(new Date()),
        shown: true
      }
    ];
    const dataCash = [
      {
        label: `${t('invoice_list')}:`,
        value: '',
        invoices,
        shown: true
      },
      {
        label: `${t('payment_method')}:`,
        value: labelPaymentMethod,
        shown: true
      },
      {
        label:
          props.paymentType === CASH_PAYMENT_COLLECTION
            ? `${t('cash_collection_date')}:`
            : props.paymentType === 'cheque'
            ? `${t('cheque_date')}:`
            : `${t('deposit_date')}:`,
        value: isValidDate(props.paymentSummary?.transactionDate)
          ? formatDateMonthYear(props.paymentSummary?.transactionDate)
          : `-`,
        shown: true
      },
      {
        label: `${t('payment_time')}:`,
        value: formatDateTime(new Date()),
        shown: true
      }
    ];
    const renderData = props.paymentType === BANK_TRANSFER ? dataBank : dataCash;
    const loadingCancelling = ref(false);
    const setLoadingCancelling = (state) => (loadingCancelling.value = state);
    const paymentStatus = ref(paymentStatusVariants[0]);
    const isCreateTopUpPayment = computed(
      () => store.getters[`sale/payment/paramsTopUpCredit`]?.totalPaidAmount > 0 ?? false
    );
    const topUpEntryPoint = computed(() => store.getters[`sale/payment/topUpCreditEntry`]);
    const topUpSummaryData = computed(() => store.getters[`sale/payment/paramsTopUpCredit`]);
    const paymentSummaryTopUp = computed(() => store.getters[`sale/payment/paymentSummaryTopUp`]);
    const backToInvoice = () => {
      emit('backToInvoice');
    };
    const onActionCancel = async () => {
      setLoadingCancelling(true);
      try {
        const { data, errors } = await apolloClient.mutate({
          mutation: cancelManualPayment,
          variables: {
            batchPaymentId: props.paymentSummary?.details?.id
          }
        });
        if (errors || !data.cancelManualPayment) {
          setLoadingCancelling(false);
          createAlert(t('unable_to_cancel'), t('payment_status_changed'), backToInvoice);
        } else {
          setLoadingCancelling(false);
          toastError(t('cancel_payment_success'));
          emit('backToInvoice');
        }
      } catch (error) {
        setLoadingCancelling(false);
        createAlert(t('errorTitle'), error.message);
      }
    };
    const onCancelPayment = () => {
      createAlertTwoAction(
        t('cancel_submitted_payment'),
        t('cancel_submitted_payment_desc'),
        t('confirm_cancel_payment'),
        onActionCancel,
        t('close'),
        'alert-cancel-payment-history'
      );
    };
    const getStorage = async () => {
      await storage.getUser().then(({ tenant }) => {
        payloadCancel.value.tenantId = tenant.id;
      });
      await storage.getSelectedCompany().then(({ id }) => {
        payloadCancel.value.customerId = id;
      });
    };
    useBackButton(10, () => {
      router.push('/sale/main/home');
    });
    onMounted(() => {
      getStorage();
    });
    return {
      TOP_UP_CREDIT_ENTRY,
      topUpEntryPoint,
      INVOICE_PAYMENT_NAME,
      paymentSummaryTopUp,
      topUpSummaryData,
      isCreateTopUpPayment,
      PENDING_APPROVAL,
      paymentStatus,
      renderData,
      remarksLabel,
      referenceNumber,
      alertCircleOutline,
      notes,
      totalBalanceUsed,
      totalPaidAmount,
      onCancelPayment,
      loadingCancelling,
      priceFormat: (price) =>
        priceFormatter(props.currencySymbol || topUpSummaryData.value.currencySymbol, price),
      formatDayDateTime,
      formatDateMonthYear
    };
  }
});
</script>
<style lang="scss" scoped>
.f-icon {
  font-size: 3rem;
}

ion-button {
  width: 97%;
  margin: 0 auto;
  --border-radius: 10px;
}
.box {
  border: 1px solid var(--ion-color-text-gray-300);
  background-color: var(--ion-color-text-gray-100);
  border-radius: 10px;
  min-height: 56px;
  width: 100%;
}
.rounded-sm {
  border-radius: 7px;
}
.bor-bot {
  border-bottom: 1px solid var(--ion-color-text-gray-300);
}
.bor-top {
  border-top: 1px solid var(--ion-color-text-gray-300);
}
</style>
