<template>
  <div class="mt-4">
    <div class="mx-3">
      <div class="box mb-2">
        <ion-grid>
          <ion-row class="ion-align-items-center ion-justify-content-between">
            <ion-col>
              <ion-row>
                <ion-label class="ml-1 mt-1"
                  >{{ isInputNote ? labelNotes : labelReferenceNumber }}
                </ion-label>
              </ion-row>
              <ion-row class="ion-align-items-center">
                <ion-input
                  :value="inputValue"
                  :placeholder="isInputNote ? labelDescTransaction : labelDescReferenceNumber"
                  @ionInput="$emit('inputRemarks', $event.target.value)"
                  name="note"
                ></ion-input>
                <ion-icon
                  @click="$emit('clearInput')"
                  v-if="inputValue !== ''"
                  size="large"
                  :icon="closeCircleOutline"
                ></ion-icon>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
  </div>
</template>
<script>
import { closeCircleOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'CompInputRemarks',
  emits: ['inputRemarks', 'clearInput'],
  props: {
    inputValue: {
      type: String,
      default: ''
    },
    isInputNote: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { t } = useI18n();
    const labelNotes = t('notes');
    const labelDescTransaction = t('enter_desc_transaction');
    const labelReferenceNumber = t('transaction_reference_number');
    const labelDescReferenceNumber = t('transaction_reference_number');
    return {
      closeCircleOutline,
      labelNotes,
      labelDescTransaction,
      labelReferenceNumber,
      labelDescReferenceNumber
    };
  }
});
</script>
<style lang="scss" scoped>
.box {
  border: 1px solid var(--ion-color-text-gray-300);
  background-color: var(--ion-color-text-gray-100);
  border-radius: 10px;
  min-height: 56px;
}
.f-icon {
  font-size: 26px;
  color: gray;
}
</style>
