<template>
  <div>
    <div class="box mx-3 mb-2" @click="openPicker(0)" v-if="isBankTransfer">
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col size="auto">
            <ion-icon class="f-icon" :icon="calendarOutline"></ion-icon>
          </ion-col>
          <ion-col>
            <ion-row>
              <ion-label class="fs-2 pb-2 text-gray-600"> {{ $t('transaction_date') }}</ion-label>
            </ion-row>
            <ion-row>
              <ion-label class="fs-3"> {{ dateTransactionFormatted || '-' }}</ion-label>
            </ion-row>
          </ion-col>
          <ion-col size="auto">
            <ion-icon class="f-icon" :icon="chevronDownOutline"></ion-icon>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="box mx-3 mb-2" @click="openPicker(1)" v-if="isBankTransfer">
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col size="auto">
            <ion-icon class="f-icon" :icon="calendarOutline"></ion-icon>
          </ion-col>
          <ion-col>
            <ion-row>
              <ion-label class="fs-2 pb-2 text-gray-600"> {{ $t('payment_value_date') }}</ion-label>
            </ion-row>
            <ion-row>
              <ion-label class="fs-3"> {{ datePaymentFormatted || '-' }}</ion-label>
            </ion-row>
          </ion-col>
          <ion-col size="auto">
            <ion-icon class="f-icon" :icon="chevronDownOutline"></ion-icon>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="box mx-3 mb-2" @click="openPicker(3)" v-if="!isBankTransfer">
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col size="auto">
            <ion-icon class="f-icon" :icon="calendarOutline"></ion-icon>
          </ion-col>
          <ion-col>
            <ion-row>
              <ion-label class="fs-2 pb-2 text-gray-600">
                {{
                  paymentType === INVOICE_PAYMENT_TYPE.CHEQUE
                    ? $t('cheque_date')
                    : paymentType === INVOICE_PAYMENT_TYPE.CASH_DEPOSIT
                    ? $t('deposit_date')
                    : $t('cash_collection_date')
                }}
              </ion-label>
            </ion-row>
            <ion-row>
              <ion-label class="fs-3"> {{ dateDepositFormatted || '-' }}</ion-label>
            </ion-row>
          </ion-col>
          <ion-col size="auto">
            <ion-icon class="f-icon" :icon="chevronDownOutline"></ion-icon>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <ion-label class="ml-5 mt-1 text-gray-400 fs-2"> {{ $t('the_date_that_effective') }} </ion-label>
  </div>
</template>
<script>
import { INVOICE_PAYMENT_TYPE } from '@/modules/shared/constants/';
import { useDateFormatter } from '@/usecases/global';
import { useMapDatePicker } from '@/usecases/invoices/useMapDatePicker';
import { pickerController } from '@ionic/vue';
import dayjs from 'dayjs';
import { calendarOutline, chevronDownOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'CompCalenderPicker',
  emits: ['dateTransaction', 'datePayment', 'dateDeposit'],
  setup(_, { emit }) {
    const { t } = useI18n();
    const route = useRoute();
    const { getDay, getMonth, getYear } = useMapDatePicker();
    const paymentType = +route.query['payment-type'];
    const isBankTransfer = ref(paymentType === INVOICE_PAYMENT_TYPE.BANK_TRANSFER);
    const { formatDateMonthYear } = useDateFormatter();

    const dateTransaction = ref(new Date());
    const datePayment = ref(new Date());
    const dateDeposit = ref(new Date());
    const dateTransactionFormatted = ref(formatDateMonthYear(dateTransaction.value));
    const datePaymentFormatted = ref(formatDateMonthYear(datePayment.value));
    const dateDepositFormatted = ref(formatDateMonthYear(dateDeposit.value));
    const openPicker = async (type) => {
      const picker = await pickerController.create({
        columns: [
          {
            name: 'month',
            options: getMonth,
            selectedIndex: dateTransaction.value.getMonth()
          },
          {
            name: 'day',
            options: getDay,
            selectedIndex: dateTransaction.value.getDate() - 1
          },
          {
            name: 'year',
            options: getYear,
            selectedIndex: getYear.map((item) => item.value).indexOf(`${dateTransaction.value.getFullYear()}`)
          }
        ],
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel'
          },
          {
            text: t('apply'),
            handler: (value) => {
              const month = value.month.value;
              const day = value.day.value;
              const year = value.year.value;
              if (type === 0) {
                dateTransaction.value = new Date(year, month - 1, day);
                dateTransactionFormatted.value = formatDateMonthYear(dateTransaction.value);
                emit('dateTransaction', {
                  date: dayjs(dateTransaction.value).toDate(),
                  type: 'DATE_TRANSACTION'
                });
                const futureDate = dayjs().add(1, 'hours');
                const compareDate = dayjs(dateTransaction.value).diff(futureDate);
                if (compareDate > 0) {
                  dateTransaction.value = new Date();
                  dateTransactionFormatted.value = formatDateMonthYear(dateTransaction.value);
                }
              } else if (type === 1) {
                datePayment.value = new Date(year, month - 1, day);
                datePaymentFormatted.value = formatDateMonthYear(datePayment.value);
                emit('datePayment', dayjs(datePayment.value).toDate());
                if (!isBankTransfer.value) {
                  const futureDate = dayjs().add(1, 'hours');
                  const compareDate = dayjs(datePayment.value).diff(futureDate);
                  if (compareDate < 0) {
                    datePayment.value = new Date();
                    datePaymentFormatted.value = formatDateMonthYear(datePayment.value);
                  }
                }
              } else {
                dateDeposit.value = new Date(year, month - 1, day);
                dateDepositFormatted.value = formatDateMonthYear(dateDeposit.value);
                emit('dateDeposit', { date: dayjs(dateDeposit.value).toDate(), type: 'DATE_DEPOSIT' });
                const futureDate = dayjs().add(1, 'hours');
                const compareDate = dayjs(dateDeposit.value).diff(futureDate);
                if (compareDate > 0) {
                  dateDeposit.value = new Date();
                  dateDepositFormatted.value = formatDateMonthYear(dateDeposit.value);
                }
              }
            }
          }
        ],
        animated: true
      });
      picker.present();
    };
    return {
      isBankTransfer,
      paymentType,
      openPicker,
      calendarOutline,
      chevronDownOutline,
      dateTransactionFormatted,
      datePaymentFormatted,
      dateDepositFormatted,
      INVOICE_PAYMENT_TYPE
    };
  }
});
</script>
<style lang="scss" scoped>
.box {
  border: 1px solid var(--ion-color-text-gray-300);
  background-color: var(--ion-color-text-gray-100);
  border-radius: 10px;
  min-height: 56px;
  padding: 5px 16px 5px 16px;
}
.f-icon {
  font-size: 27px;
  color: gray;
}
</style>
