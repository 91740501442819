<template>
  <ion-footer>
    <ion-toolbar class="mx-0">
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col>
            <ion-row>
              <ion-text>{{
                isCreateTopUpPayment ? $t('total_payment') : $t('total_invoice_amount')
              }}</ion-text>
            </ion-row>
            <ion-row>
              <ion-label class="f-price">{{ formatPrice(totalAmount) }}</ion-label>
            </ion-row>
          </ion-col>
          <ion-col size="auto">
            <ion-button
              color="primary"
              fill="solid"
              @click="$emit('handlePayInvoices')"
              :disabled="disabledPay"
            >
              <ion-icon color="light" class="f-icon" :icon="shieldCheckmarkOutline"></ion-icon>
              <ion-label>{{ $t('pay') }}</ion-label>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-footer>
</template>
<script>
import { useGetStorageData } from '@/usecases';
import { priceFormatter } from '@/utils/';
import { shieldCheckmarkOutline } from 'ionicons/icons';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { createNamespacedHelpers, useStore } from 'vuex';

const { mapGetters } = createNamespacedHelpers('sale/payment');

export default defineComponent({
  name: 'CompBottomContent',
  emits: ['handlePayInvoices'],
  props: {
    disabledPay: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { selectedUser } = useGetStorageData();
    const store = useStore();
    const isCreateTopUpPayment = computed(
      () => store.getters[`sale/payment/paramsTopUpCredit`]?.totalPaidAmount > 0 ?? false
    );
    const currency = ref(null);
    onMounted(async () => {
      const { currency_symbol } = await selectedUser?.value;
      currency.value = currency_symbol;
    });
    return {
      isCreateTopUpPayment,
      shieldCheckmarkOutline,
      priceFormatter,
      formatPrice: (price) => priceFormatter(currency.value, price)
    };
  },
  computed: {
    ...mapGetters(['selectedPaymentInvoices']),
    totalAmount() {
      return this.selectedPaymentInvoices?.totalPaidAmount ?? 0;
    }
  }
});
</script>
<style lang="scss" scoped>
ion-button {
  width: fit-content;
  --border-radius: 10px;
  height: 44px;
  ion-label {
    padding: 2rem 1rem 2rem 0;
  }
  ion-icon {
    padding-left: 1rem;
    padding-right: 6px;
  }
}
ion-label {
  text-transform: capitalize;
}
.f-price {
  font-weight: bold;
  font-size: 22px;
  margin-top: 0.7rem;
}
ion-card {
  margin-bottom: 1px;
  border: none;
}
</style>
